import React, { useState } from 'react'
import "./service.css"


const serviceImg = [
    {
        serviceNo : "01",
        serviceName : "\t\t\t  BRANDING",
        serviceImg : "/images/service-img.jfif",
    },
    {
        serviceNo : "02",
        serviceName : "Digital marketing",
        serviceImg : "/images/digital-marketing.webp",
    },
    {
        serviceNo : "03",
        serviceName : "Web Development",
        serviceImg : "/images/web-develop.webp",
    },
    {
        serviceNo : "04",
        serviceName : "UI/UX Design",
        serviceImg : "/images/ui-ux.webp",
    },
    {
        serviceNo : "05",
        serviceName : "Graphics Design",
        serviceImg : "/images/motion-graphics.webp",
    },
    {
        serviceNo : "06",
        serviceName : "PACKAGE DESIGN",
        serviceImg : "/images/print-design.webp",
    },
    {
        serviceNo : "07",
        serviceName : "ANIMATION & VIDEO EDITING",
        serviceImg : "/images/package-product-design.webp",
    },
    {
        serviceNo : "08",
        serviceName : "CONTENT & SEO STRATEGIES",
        serviceImg : "/images/seo.webp",
    }
]

const Service = () => {

    const [ hoverImg, setHoverImg ] = useState(serviceImg[0].serviceImg);

    const handleHover = (serviceImg) => {
        setHoverImg(serviceImg)
    }

    const handleMove = () => {
        setHoverImg(serviceImg[0].serviceImg)
    }


  return (
    <div className='service w-full pt-8 pb-10 lg:pt-14 lg:pb-20 px-5 md:px-0'>
        <div className='container mx-auto'>
            <div className='service__title'>
                <span>SERVICES</span>
            </div>
            <div className='hidden lg:flex gap-x-16'>
                <div className='service__content w-full lg:w-1/2'>

                    { serviceImg.map((item, index) => (

                    <div className='service__name flex items-center gap-x-14' key={index} onMouseEnter={()=>handleHover(item.serviceImg)} onMouseLeave={handleMove} >
                       <div className='service__no'><span> {item.serviceNo} </span></div>
                       <div className='service__heading'><span> {item.serviceName} </span></div>
                    </div>

                    ))}

                </div>

               

                <div className='service__img w-1/2 hidden lg:inline-block'>
                    <img src={hoverImg} alt='img' className="service__img-content" />
                </div>
            </div>

            <div className='service__phoneView lg:hidden '>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 01</span></div>
                       <div className='service__heading'><span>BRANDING</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/brandingPhn.webp' alt='img' />
                    </div>
                </div>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 02 </span></div>
                       <div className='service__heading'><span>DIGITAL MARKETING</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/digital-marketingPhn.webp' alt='img' />
                    </div>
                </div>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 03 </span></div>
                       <div className='service__heading'><span>WEB DEVELOPMENT</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/web-devPhn.webp' alt='img' />
                    </div>
                </div>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 04 </span></div>
                       <div className='service__heading'><span>UI/UX DESIGN</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/ui-uxPhn.webp' alt='img' />
                    </div>
                </div>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 05 </span></div>
                       <div className='service__heading'><span>Animation & Motion Graphics</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/motion-graphicsPhn.webp' alt='img' />
                    </div>
                </div>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 06 </span></div>
                       <div className='service__heading'><span>PRINT DESIGN</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/print-designPhn.webp' alt='img' />
                    </div>
                </div>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 07 </span></div>
                       <div className='service__heading'><span>PACKAGE DESIGN</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/product-designPhn.webp' alt='img' />
                    </div>
                </div>
                <div className='service-phoneView__content'>
                    <div className='service-phoneView__name flex mt-5'>
                       <div className='service__no mr-4'><span> 08 </span></div>
                       <div className='service__heading'><span>CONTENT & SEO STRATEGIES</span></div>
                    </div>
                    <div className='service-phoneView__img'>
                        <img src='/images/seoPhn.webp' alt='img' />
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Service