import React from 'react'
import "./about.css"

const About = () => {
  return (
    <div className='container mx-auto flex flex-wrap lg:flex-nowrap py-5 md:py-16 px-5 md:px-0'>
        <div className='about__title lg:w-1/3 xl:w-2/5'>
            <span>ABOUT US</span>
        </div>
        <div className='lg:w-2/3 xl:w-3/5'>
            <div className='about__para mt-5 lg:mt-0'>
                <span>Alright, check it : a crew of OG tech heads, creative designers,  and coders are makin' moves to set up their own turf. They're serious about droppin' innovative concepts, takin' charge and straight-up bossin' digital moves for every biz out there. You feel me?</span>
            </div>
            <div className='flex items-center flex-wrap md:flex-nowrap gap-x-12 mt-10'>
                <div className='about__our-mission'>
                    <div className='about__our-mission-title pb-5'>
                        <span>OUR MISSION</span>
                    </div>
                    <div className='about__our-mission-para pt-5'>
                        <span>In today's digital world, businesses need to go online. We're here to help your platform stand out, be present online, get more leads, and grow your business. Join with us and redefine success together.</span>
                    </div>
                </div>
                <div className='about__our-vision mt-10 md:mt-0'>
                   <div className='about__our-mission-title pb-5'>
                        <span>OUR VISION</span>
                    </div>
                    <div className='about__our-mission-para pt-5'>
                        <span>Introducing a vibrant freelancers' community connecting skilled individuals with opportunities. Members craft their own schedules and cultivate ownership skills for success.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About