import React from 'react'
import "./investor.css"

const Investor = () => {
  return (
    <div className='investor mt-10 sm:mt-14 lg:mt-0'>
        <div className='investor__container container h-full mx-auto flex justify-center items-center flex-col'>
          <div className='investor__title text-center'>
            <span>Where Branding Meets Dedication</span>
          </div>
          <div className='investor__para text-center sm:w-3/4 lg:w-2/3 xl:w-1/2 my-5 sm:my-10 px-5 sm:px-0  2xl:px-10'>
            <spn>With our awesome crew, <span className='investor-para__yello'> 'Do Anything for Brandlet' </span>, we're set to rock the branding world. Shares and investments? Let's discuss once we're dominating the scene! Here's to the journey ahead!</spn>
          </div>
          <div className='investor__btn grid place-content-center'>
            <button> Connect with Crew </button>
          </div>
        </div>
    </div>
  )
}

export default Investor