import { useEffect, useState } from "react";
import HomePage from "./component/homePage/homePage";
import "./index.css"
import "../src/component/main/main.css"
import Demo from "./component/demo/demo";
import CreativeAnimation from "./component/demo/demo";
import WordHover from "./component/demo/demo";



function App() {

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateCursorPosition = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', updateCursorPosition);

    return () => {
      document.removeEventListener('mousemove', updateCursorPosition);
    };
  }, []);


  return (
    <div className="App">

      <div className="cursor" style={{ left: cursorPosition.x, top: cursorPosition.y }}></div>
      <div className="cursor2" style={{ left: cursorPosition.x, top: cursorPosition.y }}></div>

        <HomePage />

        {/* <CreativeAnimation /> */}

         {/* <WordHover />  */}

      </div>
  );
}

export default App;
