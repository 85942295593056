import React from 'react'
import Header from '../header/header'
import Main from '../main/main'
import Marquees from '../marquee/marquee'
import About from '../about/about'
import Solution from '../solution/solution'
import Coreteam from '../coreteam\'/coreteam'
import Client from '../client/client'
import Community from '../community/community'
import Footer from '../footer/footer'
import Investor from '../investor/investor'
import Service from '../service/service'
import Workflow from '../workflow/workflow'
import Popup from '../popup/popup'
import BrandletSlider from '../slider/slider'

const HomePage = () => {
  return (
    <div>
      <Header />
      <Main />
      <Marquees />
      <BrandletSlider />
      <About />
      <Service /> 
      <Solution /> 
      {/* <Coreteam />  */}
      <Investor />
      <Client />
      <Workflow />
      <Community />
      <Footer /> 
      <Popup />
    </div>
  )
}

export default HomePage