import React, { useState, useEffect } from 'react';
import "./header.css"


const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 400); 
        };
    
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
       <div className='header__ w-full bg-white px-5'> 
        <div className={`container mx-auto header__container ${isScrolled ? 'scrolled' : ''}`}>
            <div>
                { isScrolled ? <img src='/images/icon.png' alt='img' className='header__logo' /> : <img src='/images/brandlet.jpg' alt='img' className='brandletScroll__logo'  /> }
            </div>
            <nav className='hidden lg:flex'>
                <ul>
                    <li>HOME</li>
                    <li>Services</li>
                    <li>Solutions</li>
                    <li>Crew</li>
                    <li>Connect</li>
                </ul>
            </nav>
            <div className='ml-auto flex lg:ml-0'>
            <div className='header__btn mr-5 sm:mr-10 lg:mr-0'>
                <button>ROADMAP</button>
            </div>
            <div className=' lg:hidden'>
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
               </svg>
            </div>
            </div>
        </div>
        </div>
    );
};

export default Header;
