import React from 'react'
import "./solution.css"

const Solution = () => {
  return (
    <div className='container mx-auto py-8 lg:pt-20 lg:pb-28 px-5'>
        <div className='solution__title'>
            <span>SOLUTIONS</span>
        </div>
        <div className='flex justify-center items-center flex-col md:grid place-content-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8 lg:mt-16'>
            <div className='solution__box'>
                <img src='/images/real estate.webp' alt='img' />
                <div className='solution__content flex flex-col justify-between'>
                    <div className='solution__content-title'><span>REAL ESTATE</span></div>
                    <div className='solution__para'>
                        <span> Brandlet: Elevating Real Estate Management with Seamless Solutions.</span>
                    </div>
                </div>
            </div>
            <div className='solution__box'>
                <img src='/images/education (1).webp' alt='img' />
                <div className='solution__content flex flex-col justify-between'>
                    <div className='solution__content-title'><span>Education</span></div>
                    <div className='solution__para'>
                        <span>Transform Education: Unleash Learning Potential with Comprehensive Software Solutions.</span>
                    </div>
                </div>
            </div>
            <div className='solution__box'>
                <img src='/images/heath care (1).webp' alt='img' />
                <div className='solution__content flex flex-col justify-between'>
                    <div className='solution__content-title'><span>Healthcare</span></div>
                    <div className='solution__para'>
                        <span>Empower Healthcare Evolution: Comprehensive Software for Seamless Patient Care.</span>
                    </div>
                </div>
            </div>
            <div className='solution__box'>
                <img src='/images/startup (1).webp' alt='img' />
                <div className='solution__content flex flex-col justify-between'>
                    <div className='solution__content-title'><span>New Startups & Apps</span></div>
                    <div className='solution__para'>
                        <span>Fueling Startup Triumph: Agile Solutions for Innovation and Growth.</span>
                    </div>
                </div>
            </div>
            <div className='solution__box'>
                <img src='/images/FCMG (1).webp' alt='img' />
                <div className='solution__content flex flex-col justify-between'>
                    <div className='solution__content-title'><span>FMCG & Retail</span></div>
                    <div className='solution__para'>
                        <span> Powering FMCG & Retail Excellence: Streamline, Optimize, Succeed</span>
                    </div>
                </div>
            </div>
            <div className='solution__box'>
                <img src='/images/Ecommerce.webp' alt='img' />
                <div className='solution__content flex flex-col justify-between'>
                    <div className='solution__content-title'><span>E-Commerce & D2C</span></div>
                    <div className='solution__para'>
                        <span> Unleash Your E-Commerce Potential: Seamless Integration, Maximum Growth.</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Solution