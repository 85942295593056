import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"
import "swiper/css/autoplay"
import { Keyboard, Autoplay } from "swiper/modules"
import "./slider.css"

const  BrandletSlider = () => {


    return (

      <div className=""> 
           <Swiper
              keyboard = { true }
              autoplay = { { delay: 4000 } } 
              modules= { [ Keyboard, Autoplay] }
            >

            <div className="riva-slider__image"> 
             <SwiperSlide>
               <img src="/images/moki.jpg" alt="img" />
             </SwiperSlide>
            </div>

            <div>
             <SwiperSlide className="riva-slider__image">
               <img src="/images/projectx1.jpg" alt="img" />
             </SwiperSlide>
            </div>

            <div>
             <SwiperSlide className="riva-slider__image">
                   <img src="/images/BrandletImg.jpg" alt="img" />
             </SwiperSlide>
            </div>
            
          </Swiper>
      </div>
    );
  }
export default BrandletSlider