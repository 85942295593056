import React from 'react'
import "./community.css"

const Community = () => {


  return (
    <div className='community pt-14 lg:pt-36 xl:pt-24'>
        <div className='container mx-auto flex justify-center items-center flex-col px-3'>
            <div className='community__title text-center'>
                <span>Be a member of our Clan</span>
            </div>
            <div className='community__para px-5 sm:px-12 lg:px-20 xl:px-64 2xl:px-96 text-center mt-3'>
                <span>Brandlet is on a mission to gather the most talented and skilled individuals. We want to create a group of amazing people who are ready to make a big impact in the world of branding.<br></br> If you're up for it, click here to join us and let's work together to achieve incredible things!</span>
            </div>
            <a href='https://t.me/brandletdigi' className='mt-5 communityBtn'>
                <button className='community__clickBtn'>CLICK HERE!</button>
            </a>

           {/* <div className='flex items-center justify-center sm:justify-normal flex-wrap sm:flex-nowrap gap-x-20'>
                <div className='investor-btn'>
                    <button>JOIN OUR COMMUNITY</button>
                </div>
                <div className='freelancer-btn mt-5 sm:mt-0'>
                    <button>JOIN US AS FREELANCER</button>
                </div>
            </div> */}
        </div>
        <div className='community__img'>
            <img src='/images/community.jfif' alt='img' />
        </div>
    </div>
  )
}

export default Community