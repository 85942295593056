import React, { useState } from 'react'
import "./workflow.css"

import { SiSimpleanalytics } from "react-icons/si";
import { BiPaintRoll } from "react-icons/bi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { MdOutlineDisplaySettings } from "react-icons/md";



const Workflow = () => {


    const [ hoverWord, setHoverWord ] = useState("Analysis & Reserch")

    const handleMouseEnter = (word) =>{
        setHoverWord(word)
    }

    const handleMouseLeave = () =>{
        setHoverWord("")
    }

  return (
    <div className='workflow pt-14 md:pt-20 xl:pt-5 pb-20 lg:pb-40 mt-24 lg:mt-32'>
        <div className='workflow__container container mx-auto'>
            <div className='workflow__title text-center'>
                <span>Our Work Flow</span>
            </div>
            <ul className='grid place-content-center lg:flex justify-center items-center mt-20 lg:mt-40'>
                <li className='flex justify-center lg:justify-normal items-center lg:items-start flex-col lg:flex-row workflow__box'>
                    <div>
                        <span className='workFlow__startLine'></span>
                    </div>
                    <div className='workflow__content flex justify-center items-center flex-col' onMouseEnter={() => handleMouseEnter('Analysis & Reserch')} onMouseLeave={handleMouseLeave}>
                        <div className='workflowContent__icon'>
                          <SiSimpleanalytics />
                        </div>
                        <div className='workflowContent__title text-center mt-7'>
                            <span>Analysis & Reserch</span>
                        </div>
                        { hoverWord === 'Analysis & Reserch' &&     <div className='workFlow__hoverContent hidden lg:flex justify-center items-center flex-col px-5'>
                            <div className='text-center workFlow__hoverContentTitle'>
                                <span>Analysis & Research</span>
                            </div>
                            <div className='text-center workFlow__hoverContentPara mt-3'>
                                <span>First, we identify what we need and plan accordingly by gathering research. Our diverse team then collaborates to determine requirements and create a plan for achieving our goals</span>
                            </div>
                        </div>}
                    </div>
                    <div>
                        <span className='line'></span>
                    </div>

                   
                </li>
                <li className='flex justify-center lg:justify-normal items-center lg:items-start flex-col lg:flex-row workflow__box' onMouseEnter={() => handleMouseEnter('Requirements & Planning')} onMouseLeave={handleMouseLeave}>
                    
                    <div className='workflow__content flex justify-center items-center flex-col'>
                        <div className='workflowContent__icon2'>
                        <MdOutlineDisplaySettings />
                        </div>
                        <div className='workflowContent__title text-center mt-7'>
                            <span>Requirement & Planning</span>
                        </div>
                        {hoverWord === 'Requirements & Planning' &&     <div className='workFlow__hoverContent hidden lg:flex justify-center items-center flex-col px-5'>
                            <div className='text-center workFlow__hoverContentTitle'>
                                <span>Requirements & Planning</span>
                            </div>
                            <div className='text-center workFlow__hoverContentPara mt-3'>
                                <span>We analyse market trends to devise a strategy guiding our product towards its goals, ensuring competitiveness and adaptability.</span>
                            </div>
                        </div>}
                        </div>
                    <div>
                        <span className='line'></span>
                    </div>
                  

                </li>
                <li className='flex justify-center lg:justify-normal items-center lg:items-start flex-col lg:flex-row workflow__box' onMouseEnter={() => handleMouseEnter('Design & Project Execution')} onMouseLeave={handleMouseLeave}>
                    
                    <div className='workflow__content flex justify-center items-center flex-col'>
                        <div className='workflowContent__icon3'>
                            <BiPaintRoll />
                        </div>
                        <div className='workflowContent__title text-center mt-7'>
                            <span>Design & Project Execution</span>
                        </div>
                        {hoverWord === 'Design & Project Execution' &&    <div className='workFlow__hoverContent hidden lg:flex justify-center items-center flex-col lg:px-8 2xl:px-5'>
                            <div className='text-center workFlow__hoverContentTitle'>
                                <span> Design & Project Execution</span>
                            </div>
                            <div className='text-center workFlow__hoverContentPara mt-2'>
                                <span>We amaze with our work. Our UI/UX team crafts a standout design meeting client needs and outshining competitors. Developers then execute it with advanced techniques, ensuring responsiveness</span>
                            </div>
                        </div>      }              
                        </div>
                    <div>
                        <span className='line'></span>
                    </div>


                </li>
                <li className='flex justify-center lg:justify-normal items-center lg:items-start flex-col lg:flex-row workflow__box' onMouseEnter={() => handleMouseEnter('Quality')} onMouseLeave={handleMouseLeave}>
                    
                    <div className='workflow__content flex justify-center items-center flex-col'>
                        <div className='workflowContent__icon4'>
                          <HiOutlineClipboardDocumentList />
                        </div>
                        <div className='workflowContent__title text-center mt-7'>
                            <span>Quality <br></br> Delivery</span>
                        </div>
                        {hoverWord === 'Quality' &&      <div className='workFlow__hoverContent hidden lg:flex justify-center items-center flex-col px-5'>
                            <div className='text-center workFlow__hoverContentTitle'>
                                <span>Quality Delivery</span>
                            </div>
                            <div className='text-center workFlow__hoverContentPara mt-3'>
                                <span>We consistently deliver top-notch products, valuing each one as an asset. With our eagle-eyed team, we catch defects to ensure high-quality delivery.</span>
                            </div>
                        </div>      }            
                        </div>
                    <div>
                        <span className='workFlow__endLine'></span>
                    </div>
                   
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Workflow