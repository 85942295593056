import React from 'react'
import "./marquee.css"
import Marquee from "react-fast-marquee";


// icon
import { IoIosStar } from "react-icons/io";

const Marquees = () => {
  return (
    <div>
    <div className='marquee w-full h-9 sm:h-14 flex items-center'>
        <Marquee>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span>  </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span>  </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a<span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>
            <div className='flex items-center mr-5 lg:mr-14'>
                <div className='marquee-name'><span><span className='marqueeYellow'>LET</span>'s be a <span className='marqueeYellow'>BRAND</span> </span></div>
            </div>

        </Marquee>

    </div>
    </div>
  )
}

export default Marquees